import React from 'react'
import gif from '../assets/minions.gif'
import { Link } from 'gatsby'

class Gif1Page extends React.Component {
    
    render() {
        return (
            <div class="giflayout">
                <img src={gif}></img>
                <Link to="gif2" class="button">Another one?</Link>
            </div>
        )
    }
}

export default Gif1Page